<template>
  <v-container>
    <v-card class="mx-auto">
      <v-card-text>
        <div v-html="html" />
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import privacyPolicy from '../assets/privacyPolicy.html';
import fairRefundPolicy from '../assets/fairRefundPolicy.html';
import tos from '../assets/tos.html';
import imprint from '../assets/imprint.html';

export default {
  name: 'HtmlPages',
  data: () => ({ imprint, html: '' }),
  mounted() {
    switch (this.currentRouteName) {
      case 'Imprint':
        this.html = imprint;
        break;
      case 'DataPrivacy':
        this.html = privacyPolicy;
        break;
      case 'TermsOfService':
        this.html = tos;
        break;
      case 'FairRefund':
        this.html = fairRefundPolicy;
        break;
      default:
        this.html = '';
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  metaInfo: {
    meta: [{ charset: 'utf-8' }, { name: 'robots', content: 'noindex' }],
    title: 'Cookie Scanner: online cookie and privacy checker - cookie-scanner.com',
    decription:
      'Cookie Scanner checks your website for potential privacy issues, like Cookies, third party requests and localStrorage.',
  },
};
</script>
